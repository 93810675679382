import React from "react";
import Seo from "../../components/seo";
import Layout from "../../Layout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Image1 from "./images/pumkin1.jpg";
import Image2 from "./images/pumkin2.png";
import Image3 from "./images/pumkin3.jpg";
import Image4 from "./images/pumkin4.jpg";
import Image5 from "./images/pumkin5.jpg";
import Image6 from "./images/pumkin6.jpg";
import FarmImage1 from "./images/farm1.jpg";
import FarmImage2 from "./images/farm2.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

const pumpkinBenefits = [
  {
    text: "Nutrient-Rich",
    details:
      "Packed with vitamins A, C, E, potassium, magnesium, and fiber to support immune health, vision, skin, and vitality.",
  },
  {
    text: "Antioxidant-Rich",
    details:
      "High in beta-carotene, protecting cells from damage and promoting healthy skin.",
  },
  {
    text: "Supports Vision",
    details:
      "Vitamin A aids in good vision and helps prevent age-related eye issues.",
  },
  {
    text: "Digestive Health",
    details: "Fiber promotes regular digestion and gut health.",
  },
  {
    text: "Heart Health",
    details:
      "Potassium regulates blood pressure, while fiber helps lower cholesterol.",
  },
];

const PumpkinPage = () => (
  <Layout>
    <Seo title="Pumpkin" />
    <Breadcrumbs title="Pumpkin" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size { width: 200px; height: 200px; object-fit: cover; margin: 3px; }
              .narrow-paragraph { max-width: 700px; margin: 0 auto; }
              .custom-table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              .custom-table th, .custom-table td { border: 1px solid #ddd; padding: 8px; }
              .custom-table th { background-color: #f2f2f2; text-align: left; }
              .custom-table .green-header { background-color: #d4edda; }
              .margin-bottom { margin-bottom: 50px; }
              .farm-description { display: flex; align-items: center; margin-bottom: 15px; }
              .farm-description img { margin-right: 15px; width: 200px; height: 200px; object-fit: cover; }
              
              @media (max-width: 768px) {
                .fixed-size { width: 100%; height: auto; }
                .narrow-paragraph { padding: 0 15px; }
                .custom-table, .custom-table th, .custom-table td { font-size: 14px; }
                .farm-description img { width: 100%; height: auto; margin-right: 10px; }
                .margin-bottom { margin-bottom: 30px; }
              }
            `}
          </style>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img
                      src={image}
                      alt={`Pumpkin field ${index + 1}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-4" key={index + 3}>
                    <img
                      src={image}
                      alt={`Pumpkin field ${index + 4}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Pumpkins are not just for carving and baking pies—they are a
                    highly nutritious and versatile vegetable that can elevate a
                    variety of dishes while offering a host of health benefits.
                    Native to North America, pumpkins are a member of the squash
                    family and come in a wide range of sizes, colors, and
                    textures. When grown organically, pumpkins offer even more
                    advantages, from superior taste to environmental
                    sustainability. Let’s dive into why pumpkins are a fantastic
                    addition to your organic diet.
                  </p>
                  <p>
                    Organic pumpkins are grown without synthetic pesticides or
                    harmful chemicals, ensuring that they are as pure and
                    natural as possible. By opting for organic pumpkins, you are
                    supporting farming practices that are better for the
                    planet—preserving soil health, promoting biodiversity, and
                    reducing the carbon footprint of food production.
                    Additionally, organic pumpkins tend to have a more robust
                    flavor, as they are grown in rich, healthy soil that
                    nourishes the vegetable without the aid of artificial
                    fertilizers.
                  </p>
                </div>
              </div>
              <h3>
                <strong>Nutrition Table</strong>
              </h3>
              <table className="custom-table margin-bottom">
                <thead>
                  <tr>
                    <th className="green-header">Nutrient</th>
                    <th className="green-header">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Calories</td>
                    <td>110 kcal</td>
                  </tr>
                  <tr>
                    <td>Carbohydrates</td>
                    <td>26 g</td>
                  </tr>
                  <tr>
                    <td>Protein</td>
                    <td>3 g</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>0 g</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>3 g</td>
                  </tr>
                  <tr>
                    <td>Sugar</td>
                    <td>1 g</td>
                  </tr>
                  <tr>
                    <td>Vitamin C</td>
                    <td>15 mg (25% of DV)</td>
                  </tr>
                  <tr>
                    <td>Potassium</td>
                    <td>620 mg (18% of DV)</td>
                  </tr>
                  <tr>
                    <td>Vitamin B6</td>
                    <td>0.3 mg (20% of DV)</td>
                  </tr>
                  <tr>
                    <td>Iron</td>
                    <td>1 mg (6% of DV)</td>
                  </tr>
                  <tr>
                    <td>Folate</td>
                    <td>20 mcg (5% of DV)</td>
                  </tr>
                </tbody>
              </table>
              <h3>
                <strong>Our Organic Farms</strong>
              </h3>
              <div className="farm-description">
                <img
                  src={FarmImage1}
                  alt="Organic Farm 1"
                  className="img-thumbnail"
                />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img
                  src={FarmImage2}
                  alt="Organic Farm 2"
                  className="img-thumbnail"
                />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-12 col-md-4 ml-auto">
              <h2>Health Benefits of Pumpkins</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {pumpkinBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default PumpkinPage;
